'use strict';

import 'slick';

export default class Slick {
    constructor() {
        $('.section__carousel').slick({
            nextArrow: '<button class="slick-next icon icon-chevron-right-thin" type="button"><span class="sr-only">Next slide</span></button>',
            prevArrow: '<button class="slick-prev icon icon-chevron-left-thin" type="button"><span class="sr-only">Previous slide</span></button>'
        });
    }
}
